import { decodeValue } from '../../../../utils';

export function Success() {
  const queryParams = new URLSearchParams(window.location.search);
  const secondary = decodeValue(queryParams.get('secondaryColor'));
  const secondaryColor = secondary ? `${secondary}` : '#606B85';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      width="245"
      height="200"
      viewBox="0 0 798.70383 481.87259"
    >
      <path
        d="M287.17829,664.086a3.61323,3.61323,0,0,1-2.61865-6.26262c.09111-.36213.15647-.62217.24758-.9843a9.70742,9.70742,0,0,0-17.99686-.16973c-4.28226,10.0234-9.82453,20.4-7.06826,31.44011-18.206-38.58538-12.01458-86.582,14.89139-119.57967,7.43714-4.09684,13.46508-11.37558,15.03439-19.82719-3.6807,1.24714-8.26984-1.74675-5.50237-5.62382,1.19955-1.48179,2.41075-2.95192,3.61019-4.4338-13.98171-14.89038-30.64069,8.512-15.25086,28.60947a115.84683,115.84683,0,0,0-13.01134,20.35185,48.70929,48.70929,0,0,0-2.908-22.62447c-2.78347-6.71479-8.00064-12.37-12.595-18.17495-5.58608-7.07308-17.08305-3.80993-17.8347,5.16593,5.15526,1.69443,5.825,9.14116-.072,10.16036,1.54455,16.69795,12.45345,32.04321,27.47828,39.36635a116.2989,116.2989,0,0,0-6.35539,48.6114c-2.65207-19.25-23.31569-23.92147-37.75859-31.57247a5.626,5.626,0,0,0-8.58011,5.69851,32.49548,32.49548,0,0,1,5.69584,3.00488,4.91123,4.91123,0,0,1-2.28162,8.96546c7.28014,18.01805,26.10227,30.11728,45.43371,30.04916a119.56213,119.56213,0,0,0,8.03167,23.447h28.69167c.10291-.3188.19542-.64792.288-.96672a32.59875,32.59875,0,0,1-7.93916-.473c7.02637-8.31908,12.46031-12.56336,8.371-24.17721Z"
        transform="translate(-200.64808 -209.0637)"
        fill="#f2f2f2"
      />
      <polygon points="149.443 155.77 154.114 197.9 213.77 198.929 206.57 148.529 149.443 155.77" fill="#ffb7b7" />
      <polygon points="243.842 467.83 256.016 464.542 249.125 416.022 231.158 420.875 243.842 467.83" fill="#ffb7b7" />
      <path
        d="M440.3116,673.758l23.97412-6.47621.001-.00026a15.82666,15.82666,0,0,1,19.40482,11.15159l.1341.49648L444.573,689.53288Z"
        transform="translate(-200.64808 -209.0637)"
        fill="#2f2e41"
      />
      <polygon points="87.055 468.468 99.665 468.467 105.664 419.829 87.053 419.83 87.055 468.468" fill="#ffb7b7" />
      <path
        d="M284.48645,673.41456l24.83345-.001h.001a15.82667,15.82667,0,0,1,15.82582,15.82556v.51428l-40.65951.0015Z"
        transform="translate(-200.64808 -209.0637)"
        fill="#2f2e41"
      />
      <path
        d="M353.81641,382.44916l-8.49377,15.28878a102.11268,102.11268,0,0,0-9.09219,11.16432c-.7859,1.09211-3.01788.89588-3.85538,2.13673-.59954.8883.20583,3.17792-.41244,4.14059q-.99374,1.54728-2.01021,3.22039c-.24826.40931-2.04749-1.40078-.74658,1.24459.21151.4301-.48952.82973-.73478,1.25251-8.60912,14.84075-17.54671,36.16527-18.82224,62.06166l-4.09463,10.033,1.39217,8.64178-1.39217,6.25871-.49083,6.75335-.76934,5.3164-4.94035,34.13924A437.55142,437.55142,0,0,0,284.6935,655.40708h19.37064l29.0669-93.02216,31.97242-69.0812,31.5153,44.35062,1.31849,5.999,4.60439,2.33606,1.3558,5.11419,4.78752,3.53113,6.1754,8.69048c4.0991,31.06494,3.72995,57.964,17.348,80.16145l22.35074-2.9801-10.28551-97.94144-27.79752-98.87472-2.81494-53.8357Z"
        transform="translate(-200.64808 -209.0637)"
        fill="#2f2e41"
      />
      <path
        d="M348.76562,388.68825,342.50943,353.497l-2.29717-26.78961c-1.30076-15.19627-3.43663-10.646,5.57326-22.952l22.35074-14.90049h17.88059l8.9403,14.90049,18.07074,16.38141c11.70532,11.7691,2.54125,23.03648,1.26384,24.48791l1.52611,58.964c12.102,28.596-7.36276,19.6442-14.9005,20.86069-22.35074-2.9801-81.95271-14.9005-55.13182-28.31094C344.73856,392.09488,345.80953,389.67083,348.76562,388.68825Z"
        transform="translate(-200.64808 -209.0637)"
        fill="#e6e6e6"
      />
      <path
        d="M500.49093,321.96083a9.64472,9.64472,0,0,0-13.74873,5.44862l-21.98044,1.617-2.27521,13.59712,31.00857-2.71949a9.697,9.697,0,0,0,6.99581-17.94325Z"
        transform="translate(-200.64808 -209.0637)"
        fill="#ffb7b7"
      />
      <path
        d="M487.0205,342.04525l-6.36223,2.801a18.77539,18.77539,0,0,1-10.33628,1.386l-7.33227-1.09412-3.461,1.6525a18.77531,18.77531,0,0,1-13.14968,1.13755l-1.59614-.44668-2.1645,1.1364a18.77533,18.77533,0,0,1-15.00074,1.0729h0l-13.49042,1.73633-38.704-24.73871a12.7879,12.7879,0,0,1-5.3966-2.5136,12.41363,12.41363,0,0,1,9.63288-21.93346l34.43408,19.21712,1.57941-.65676a24.76415,24.76415,0,0,1,22.35442,1.69433l.49119.298,1.5406-.65862a24.7641,24.7641,0,0,1,19.80414.14614l4.30247,1.91491h0a24.7641,24.7641,0,0,1,16.3303-.222l3.99009,1.33312Z"
        transform="translate(-200.64808 -209.0637)"
        fill="#e6e6e6"
      />
      <path
        d="M425.57563,474.07955a9.64475,9.64475,0,0,0-8.9445-11.77757L409.164,441.56563l-13.70817,1.46431,10.95636,29.13564a9.697,9.697,0,0,0,19.16347,1.914Z"
        transform="translate(-200.64808 -209.0637)"
        fill="#ffb7b7"
      />
      <path
        d="M402.60906,466.505l-4.40846-5.37487a18.77543,18.77543,0,0,1-4.114-9.583l-.91753-7.35646-2.52218-2.88925a18.77532,18.77532,0,0,1-4.6311-12.35965l.00109-1.65747-1.67651-1.77926a18.77538,18.77538,0,0,1-5.06652-14.15994h0l-5.29945-12.52686-3.68072-61.80459-.7228-12.73116a12.788,12.788,0,0,1,.97011-5.8737,12.41364,12.41364,0,0,1,23.71576,3.38113l8.57376,68.93846,1.05722,1.34467a24.76411,24.76411,0,0,1,4.3783,21.98685l-.155.55323,1.04858,1.3068a24.76412,24.76412,0,0,1,5.18381,19.11422l-.68764,4.65889h0a24.76417,24.76417,0,0,1,4.60437,15.66933l-.21124,4.2016Z"
        transform="translate(-200.64808 -209.0637)"
        fill="#e6e6e6"
      />
      <circle cx="179.13872" cy="51.03446" r="24.30613" fill="#ffb8b8" />
      <path
        d="M396.4472,278.4246l-5.245-25.12221c4.6043,1.87739,15.83933-.58114,18.65545-4.67878,2.81622-4.09763-3.08441-9.41712-6.48168-13.03793-2.4437-2.59267-5.97511-3.85927-9.447-4.60429a91.00911,91.00911,0,0,0-11.2052-1.40065c-4.75326-.38743-9.56609-.77476-14.24487.16388A25.302,25.302,0,0,0,348.408,257.53408c.61088,5.05129,2.72682,10.17706,1.26651,15.04946-1.49,4.947-6.25823,8.16553-8.71676,12.71017-2.14567,3.99332-2.36922,8.82106-1.44539,13.26147a50.01991,50.01991,0,0,0,5.00663,12.62068c3.48667,6.72013,7.46507,13.4701,13.60416,17.88059a25.8218,25.8218,0,0,0,8.523,3.99323,36.8106,36.8106,0,0,0,4.02315-20.62214,43.50326,43.50326,0,0,1,9.71506,20.72654,23.11581,23.11581,0,0,0,4.48506-1.639,25.18806,25.18806,0,0,0,13.932-22.75309c-.20863-6.73505,6.35781,11.043,12.54619,8.4039C422.85119,312.26029,418.87879,320.67674,396.4472,278.4246Z"
        transform="translate(-200.64808 -209.0637)"
        fill="#2f2e41"
      />
      <path
        d="M905.54758,443.45075c-8.6017-13.34184-40.5815-24.08576-76.80441-25.80329l-2.11777-.944c14.62716,10.55564,21.593,22.59462,19.95145,34.48121-.94494,6.84111-4.667,13.58432-6.43667,20.40223-.97255,3.747.25026,8.5005,9.63073,9.81679,6.34266.89008,13.341-.3492,18.92422-1.78891C898.8049,471.85046,914.14947,456.79258,905.54758,443.45075Z"
        transform="translate(-200.64808 -209.0637)"
        fill="#f2f2f2"
      />
      <path
        d="M200.64808,689.74629a1.18647,1.18647,0,0,0,1.19007,1.19h373.29a1.19,1.19,0,0,0,0-2.38h-373.29A1.18651,1.18651,0,0,0,200.64808,689.74629Z"
        transform="translate(-200.64808 -209.0637)"
        fill="#ccc"
      />
      <path
        d="M875.8754,487.20344h-253.5a38.25,38.25,0,0,1,0-76.5h253.5a38.25,38.25,0,1,1,0,76.5Zm-253.5-74.5a36.25,36.25,0,0,0,0,72.5h253.5a36.25,36.25,0,1,0,0-72.5Z"
        transform="translate(-200.64808 -209.0637)"
        fill="#3f3d56"
      />
      <path
        d="M620.1254,461.20344a7.00818,7.00818,0,0,1-7-7v-18a7,7,0,0,1,14,0v18A7.00818,7.00818,0,0,1,620.1254,461.20344Z"
        transform="translate(-200.64808 -209.0637)"
        fill={secondaryColor}
      />
      <path
        d="M640.1254,461.20344a7.00818,7.00818,0,0,1-7-7v-18a7,7,0,0,1,14,0v18A7.00818,7.00818,0,0,1,640.1254,461.20344Z"
        transform="translate(-200.64808 -209.0637)"
        fill={secondaryColor}
      />
      <path
        d="M660.1254,461.20344a7.00818,7.00818,0,0,1-7-7v-18a7,7,0,0,1,14,0v18A7.00818,7.00818,0,0,1,660.1254,461.20344Z"
        transform="translate(-200.64808 -209.0637)"
        fill="#ff6584"
      />
      <path
        d="M680.1254,461.20344a7.00818,7.00818,0,0,1-7-7v-18a7,7,0,0,1,14,0v18A7.00818,7.00818,0,0,1,680.1254,461.20344Z"
        transform="translate(-200.64808 -209.0637)"
        fill={secondaryColor}
      />
      <polygon
        points="487.477 202.64 485.477 202.64 485.477 127.597 342.82 127.597 342.82 125.597 487.477 125.597 487.477 202.64"
        fill="#3f3d56"
      />
      <circle cx="320.53012" cy="120.78" r="27.78" fill={secondaryColor} />
      <path
        d="M517.38844,344.3019a3.08923,3.08923,0,0,1-2.47186-1.23631l-7.57881-10.10525a3.09007,3.09007,0,1,1,4.94423-3.70792l4.95831,6.61058,12.7348-19.10194a3.0902,3.0902,0,0,1,5.1424,3.42827l-15.15762,22.73643a3.09139,3.09139,0,0,1-2.48544,1.37513C517.44578,344.3014,517.41711,344.3019,517.38844,344.3019Z"
        transform="translate(-200.64808 -209.0637)"
        fill="#fff"
      />
      <polygon
        points="606.084 202.64 604.084 202.64 604.084 125.597 748.741 125.597 748.741 127.597 606.084 127.597 606.084 202.64"
        fill="#3f3d56"
      />
      <circle cx="770.92382" cy="120.78" r="27.78" fill={secondaryColor} />
      <path
        d="M967.78214,344.3019a3.08923,3.08923,0,0,1-2.47186-1.23631l-7.57881-10.10525a3.09007,3.09007,0,1,1,4.94423-3.70792L967.634,335.863l12.73479-19.10194a3.0902,3.0902,0,0,1,5.1424,3.42827l-15.15762,22.73643a3.09139,3.09139,0,0,1-2.48544,1.37513C967.83948,344.3014,967.81081,344.3019,967.78214,344.3019Z"
        transform="translate(-200.64808 -209.0637)"
        fill="#fff"
      />
      <rect x="544.78054" y="42.89315" width="2" height="159.74658" fill="#3f3d56" />
      <circle cx="548.53012" cy="27.78" r="27.78" fill={secondaryColor} />
      <path
        d="M745.38844,251.3019a3.08923,3.08923,0,0,1-2.47186-1.23631l-7.57881-10.10525a3.09007,3.09007,0,1,1,4.94423-3.70792l4.95831,6.61058,12.7348-19.10194a3.0902,3.0902,0,0,1,5.1424,3.42827l-15.15762,22.73643a3.09139,3.09139,0,0,1-2.48544,1.37513C745.44578,251.3014,745.41711,251.3019,745.38844,251.3019Z"
        transform="translate(-200.64808 -209.0637)"
        fill="#fff"
      />
      <path
        d="M715.2934,435.72785q32.3996-1.70821,64.85624-1.8184,32.31974-.10381,64.61828,1.38953,18.25382.84423,36.47894,2.19874c1.92593.14279,1.91683-2.85789,0-3q-32.35579-2.39882-64.80271-3.2012-32.31018-.79306-64.63321.01108-18.26767.45474-36.51754,1.42025c-1.92063.10126-1.93258,3.10189,0,3Z"
        transform="translate(-200.64808 -209.0637)"
        fill="#3f3d56"
      />
      <path
        d="M715.2934,448.72785q32.3996-1.70821,64.85624-1.8184,32.31974-.10381,64.61828,1.38953,18.25382.84423,36.47894,2.19874c1.92593.14279,1.91683-2.85789,0-3q-32.35579-2.39882-64.80271-3.2012-32.31018-.79306-64.63321.01108-18.26767.45474-36.51754,1.42025c-1.92063.10126-1.93258,3.10189,0,3Z"
        transform="translate(-200.64808 -209.0637)"
        fill="#3f3d56"
      />
      <path
        d="M715.2934,461.72785q32.3996-1.70821,64.85624-1.8184,32.31974-.10381,64.61828,1.38953,18.25382.84423,36.47894,2.19874c1.92593.14279,1.91683-2.85789,0-3q-32.35579-2.39882-64.80271-3.2012-32.31018-.79306-64.63321.01108-18.26767.45474-36.51754,1.42025c-1.92063.10126-1.93258,3.10189,0,3Z"
        transform="translate(-200.64808 -209.0637)"
        fill="#3f3d56"
      />
      <polygon points="171.977 128.64 168.898 193.49 178.686 211.64 174.977 187.64 171.977 128.64" opacity="0.2" />
    </svg>
  );
}
