import { useAppStateContext } from '../../AppStateProvider/AppStateProvider';
import { Button, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ErrorIcon } from '../../../icons/ErrorIcon';
import Logo from './Logo.png';
import { GetStartedImg } from './GetStartedImg';
import { decodeValue } from '../../../utils';

const queryParams = new URLSearchParams(window.location.search);
const secondary = decodeValue(queryParams.get('secondaryColor'));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      justifyContent: 'space-between',
    },
    /* 
    The size of the image is explicitly stated here so that this content can properly be centered vertically
    before the image is loaded.
    */
    illustration: {
      borderBottomLeftRadius: '10%',
      borderBottomRightRadius: '30%',
      borderTopLeftRadius: '50%',
      borderTopRightRadius: '70%',
      width: '284px',
      height: '284px',
      [theme.breakpoints.down('md')]: {
        width: '200px',
        height: '200px',
      },
    },
  })
);

export function GetStarted() {
  const { nextPane, state } = useAppStateContext();
  const classes = useStyles();

  return (
    <Container>
      <Grid container alignItems="center" className={classes.gridContainer}>
        <Grid item lg={5}>
          {state.appIsExpired ? (
            <>
              <Typography variant="h1" gutterBottom>
                <ErrorIcon />
                App has expired.
              </Typography>

              <Typography variant="body1" gutterBottom>
                Please re-deploy the application and try again.
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h1" gutterBottom>
                Let's get started.
              </Typography>

              <Typography variant="body1" gutterBottom>
                Your online notarization requires a webcam, microphone and quality connection. Let's check your setup to
                ensure a smooth experience.
              </Typography>
            </>
          )}

          <Button
            variant="contained"
            color="primary"
            onClick={nextPane}
            style={{ marginBottom: '1em' }}
            disabled={state.appIsExpired}
          >
            Get started
          </Button>
        </Grid>

        <Grid item lg={5}>
          {secondary ? <GetStartedImg /> : <img src={Logo} alt="eNotaryLog Logo" className={classes.illustration} />}
          <Typography variant="body1" gutterBottom>
            <strong>Not sure about something?</strong> Visit our&nbsp;
            <a href="https://enotarylog.atlassian.net/servicedesk/customer/portal/1?">FAQs</a> for support.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
