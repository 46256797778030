import { decodeValue } from '../../../../utils';

export function Excellent() {
  const queryParams = new URLSearchParams(window.location.search);
  const secondary = decodeValue(queryParams.get('secondaryColor'));
  const secondaryColor = secondary ? `${secondary}` : '#606B85';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      width="200"
      height="167"
      viewBox="0 0 601.98492 604.98985"
    >
      <path
        id="b71ccbba-e8b4-459a-9884-0222885cf9eb-2783"
        data-name="Path 1"
        d="M898.16284,184.0847,299.52852,256.092a.36067.36067,0,0,1-.20294-.03421.4665.4665,0,0,1-.16562-.13995.71088.71088,0,0,1-.112-.22316.97327.97327,0,0,1-.04043-.28458,1.07587,1.07587,0,0,1,.04121-.29469.93856.93856,0,0,1,.11429-.25036.668.668,0,0,1,.16562-.17962.43481.43481,0,0,1,.20294-.0832l598.63121-73.001a1.58754,1.58754,0,0,1,.5637.02877,1.28408,1.28408,0,0,1,.4611.20993,1.051,1.051,0,0,1,.311.3569,1.00182,1.00182,0,0,1,.11428.46652,1.176,1.176,0,0,1-.11428.49763,1.48785,1.48785,0,0,1-.311.43231,1.74938,1.74938,0,0,1-.4611.3219A1.78824,1.78824,0,0,1,898.16284,184.0847Z"
        transform="translate(-299.00754 -147.50507)"
        fill="#cacaca"
      />
      <path
        id="fbe4cf51-249d-4766-a31e-46e69d2d3573-2784"
        data-name="Path 1"
        d="M899.24108,752.487,299.39146,691.42519a.36078.36078,0,0,1-.19049-.07789.46639.46639,0,0,1-.13088-.17288.71052.71052,0,0,1-.06029-.24228.97368.97368,0,0,1,.023-.28652,1.07591,1.07591,0,0,1,.10485-.27847.93822.93822,0,0,1,.16643-.21919.66794.66794,0,0,1,.201-.13891.43459.43459,0,0,1,.21625-.03666L899.78585,750.064a1.58757,1.58757,0,0,1,.54366.15172,1.28378,1.28378,0,0,1,.40381.306,1.0509,1.0509,0,0,1,.22518.41642,1.002,1.002,0,0,1,.00915.48023,1.17578,1.17578,0,0,1-.22065.46044,1.48768,1.48768,0,0,1-.39829.35356,1.74923,1.74923,0,0,1-.52048.21291A1.78844,1.78844,0,0,1,899.24108,752.487Z"
        transform="translate(-299.00754 -147.50507)"
        fill="#cacaca"
      />
      <path
        id="aefee446-e8cd-48bc-a6ae-8085c53a4aa5-2785"
        data-name="Ellipse 1"
        d="M311.41634,227.48627a4.0837,4.0837,0,0,1,2.29686.311,5.07349,5.07349,0,0,1,1.88476,1.50454,7.64866,7.64866,0,0,1,1.27517,2.46714,10.61088,10.61088,0,0,1,.46652,3.18792,11.959,11.959,0,0,1-.46652,3.31777,10.62571,10.62571,0,0,1-1.27517,2.82713,7.67816,7.67816,0,0,1-1.88476,2.03794,5.02758,5.02758,0,0,1-2.29686.95171,4.08435,4.08435,0,0,1-2.28519-.34912,5.12549,5.12549,0,0,1-1.85754-1.52475,7.70621,7.70621,0,0,1-1.24874-2.45392,10.62934,10.62934,0,0,1-.45719-3.14905,11.96214,11.96214,0,0,1,.45719-3.27733,10.67361,10.67361,0,0,1,1.248-2.8077,7.76275,7.76275,0,0,1,1.85832-2.04882A5.08828,5.08828,0,0,1,311.41634,227.48627Z"
        transform="translate(-299.00754 -147.50507)"
        fill="#3f3d56"
      />
      <path
        id="a4b9cd5e-e7a9-439c-9762-a8720b874a73-2786"
        data-name="Ellipse 2"
        d="M332.21712,224.36522a4.35417,4.35417,0,0,1,2.40338.311,5.26561,5.26561,0,0,1,1.975,1.52942,7.73287,7.73287,0,0,1,1.33426,2.51924,10.614,10.614,0,0,1,.48751,3.26023,11.99315,11.99315,0,0,1-.48985,3.39552,10.79146,10.79146,0,0,1-1.33192,2.89245,7.96221,7.96221,0,0,1-1.97185,2.09,5.34255,5.34255,0,0,1-2.40338.98592,4.35422,4.35422,0,0,1-2.39094-.35067,5.31609,5.31609,0,0,1-1.94385-1.55508,7.78958,7.78958,0,0,1-1.3086-2.50524,10.633,10.633,0,0,1-.47819-3.219,11.99565,11.99565,0,0,1,.47819-3.35275,10.83673,10.83673,0,0,1,1.30549-2.87691,8.04687,8.04687,0,0,1,1.94385-2.09936A5.40474,5.40474,0,0,1,332.21712,224.36522Z"
        transform="translate(-299.00754 -147.50507)"
        fill="#3f3d56"
      />
      <path
        id="a94385bb-e1a1-4da4-9c03-9925c8c84fb9-2787"
        data-name="Ellipse 3"
        d="M353.9906,221.09333a4.65432,4.65432,0,0,1,2.51768.30713,5.4746,5.4746,0,0,1,2.06593,1.56286,7.82362,7.82362,0,0,1,1.39957,2.57443,10.619,10.619,0,0,1,.514,3.33255,12.03072,12.03072,0,0,1-.514,3.47716,10.96861,10.96861,0,0,1-1.39957,2.96865,8.27,8.27,0,0,1-2.06593,2.146,5.68625,5.68625,0,0,1-2.51768,1.01546,4.65042,4.65042,0,0,1-2.50446-.35067,5.52061,5.52061,0,0,1-2.03638-1.58385,7.87964,7.87964,0,0,1-1.36536-2.562,10.63746,10.63746,0,0,1-.50073-3.28822,12.03232,12.03232,0,0,1,.50073-3.43673,11.0118,11.0118,0,0,1,1.36536-2.94688,8.354,8.354,0,0,1,2.03716-2.15845A5.75373,5.75373,0,0,1,353.9906,221.09333Z"
        transform="translate(-299.00754 -147.50507)"
        fill="#3f3d56"
      />
      <path
        id="e9fb7174-288f-46c4-ab91-f423808d7a61-2788"
        data-name="Path 2"
        d="M865.90034,152.40069l-36.50167,5.27717a2.75108,2.75108,0,0,1-1.02558-.0381,2.234,2.234,0,0,1-.83663-.38877,1.92525,1.92525,0,0,1-.56294-.67024,1.96324,1.96324,0,0,1-.20683-.88718,2.36293,2.36293,0,0,1,.20683-.95093,2.91411,2.91411,0,0,1,.56294-.83508,3.27143,3.27143,0,0,1,.83663-.63059,3.202,3.202,0,0,1,1.02558-.33745l36.50089-5.39924a2.97011,2.97011,0,0,1,1.08.0311,2.37156,2.37156,0,0,1,.88329.38877,1.94386,1.94386,0,0,1,.81331,1.60407,2.37861,2.37861,0,0,1-.21849.9797,3.00364,3.00364,0,0,1-.59482.86229,3.47355,3.47355,0,0,1-1.96329.99681Z"
        transform="translate(-299.00754 -147.50507)"
        fill="#3f3d56"
      />
      <path
        id="f4f63b1d-19b6-4660-a2ee-fe9c59ab2b83-2789"
        data-name="Path 3"
        d="M865.90034,161.51269l-36.50167,5.054a2.75343,2.75343,0,0,1-1.02558-.04432,2.24946,2.24946,0,0,1-.83663-.38877,1.94378,1.94378,0,0,1-.56294-.67336,1.97184,1.97184,0,0,1-.20683-.89883,2.35357,2.35357,0,0,1,.20683-.94938,2.88931,2.88931,0,0,1,.56294-.83119,3.24131,3.24131,0,0,1,.83663-.622,3.188,3.188,0,0,1,1.02558-.33124l36.50089-5.17531a2.97184,2.97184,0,0,1,1.08.0381,2.38781,2.38781,0,0,1,.88329.39655,1.996,1.996,0,0,1,.59637.68967,1.97579,1.97579,0,0,1,.21694.91673,2.36934,2.36934,0,0,1-.21849.97814,2.97736,2.97736,0,0,1-.59637.8553,3.4445,3.4445,0,0,1-1.9633.98436Z"
        transform="translate(-299.00754 -147.50507)"
        fill="#3f3d56"
      />
      <path
        id="eb3f2030-579f-46ae-b7f9-06846804faba-2790"
        data-name="Path 4"
        d="M865.90034,170.62469l-36.50167,4.82076a2.75651,2.75651,0,0,1-1.02558-.05054,2.26578,2.26578,0,0,1-.83663-.39655,1.94633,1.94633,0,0,1-.76977-1.56752,2.34439,2.34439,0,0,1,.20605-.9486,2.86438,2.86438,0,0,1,.56372-.82808,3.21378,3.21378,0,0,1,.83663-.622,3.16786,3.16786,0,0,1,1.02558-.325l36.50089-4.942a2.97412,2.97412,0,0,1,1.08.0451,2.40482,2.40482,0,0,1,.88329.402,2.01379,2.01379,0,0,1,.59637.69356,1.98445,1.98445,0,0,1,.21694.9175,2.35987,2.35987,0,0,1-.21849.97659,2.95473,2.95473,0,0,1-.59637.8553,3.42119,3.42119,0,0,1-1.9633.97193Z"
        transform="translate(-299.00754 -147.50507)"
        fill="#3f3d56"
      />
      <path
        id="ed3b43a5-e52b-42db-ae2c-bbf19919dda8-2791"
        data-name="Rectangle 1"
        d="M692.35937,254.7663l176.31832-14.93346V344.93307l-176.31832,2.30074Z"
        transform="translate(-299.00754 -147.50507)"
        fill="#f0f0f0"
      />
      <path
        id="ac2eae0c-5080-4fe0-b0c3-f9d754c11698-2792"
        data-name="Path 5"
        d="M829.75633,392.44315l-104.71224-2.0807a4.70646,4.70646,0,0,1-3.22369-1.35836,4.53543,4.53543,0,0,1-.97425-1.42213,4.344,4.344,0,0,1-.3569-1.72614,4.26008,4.26008,0,0,1,.3569-1.71059,4.33223,4.33223,0,0,1,.97425-1.39335,4.48567,4.48567,0,0,1,1.447-.92839,4.63641,4.63641,0,0,1,1.77668-.32423l104.71145,1.37936a5.88213,5.88213,0,0,1,2.07448.40354,5.52055,5.52055,0,0,1,1.69893,1.05046,4.915,4.915,0,0,1,1.14765,1.53875,4.4002,4.4002,0,0,1,.42143,1.87465,4.30851,4.30851,0,0,1-.42143,1.86066,4.68866,4.68866,0,0,1-1.14765,1.50376,5.28714,5.28714,0,0,1-1.69893.99448,5.78181,5.78181,0,0,1-2.07448.33512Z"
        transform="translate(-299.00754 -147.50507)"
        fill="#e4e4e4"
      />
      <path
        id="b3e575b2-619a-4403-8cc7-960fb099e65d-2793"
        data-name="Path 6"
        d="M811.76712,375.92042l-70.8892-.59559a4.85512,4.85512,0,0,1-1.81944-.367,4.73366,4.73366,0,0,1-1.48277-.97115,4.51674,4.51674,0,0,1-.99759-1.42834,4.32549,4.32549,0,0,1,0-3.48028,4.45388,4.45388,0,0,1,.99759-1.41823,4.66526,4.66526,0,0,1,1.482-.9556,4.82852,4.82852,0,0,1,1.82022-.34912l70.8892.1213a5.63487,5.63487,0,0,1,2.0216.37477,5.29173,5.29173,0,0,1,1.65616,1.01781,4.77,4.77,0,0,1,1.11967,1.51076,4.34642,4.34642,0,0,1,0,3.68865,4.70269,4.70269,0,0,1-1.11888,1.49755,5.2221,5.2221,0,0,1-1.65616,1.00147,5.60555,5.60555,0,0,1-2.02161.35456Z"
        transform="translate(-299.00754 -147.50507)"
        fill="#e4e4e4"
      />
      <path
        id="b6f8ac03-6581-403f-aeca-47fca949167f-2794"
        data-name="Rectangle 2"
        d="M692.35937,422.91345l176.31832,8.039V536.05267L692.35937,515.38019Z"
        transform="translate(-299.00754 -147.50507)"
        fill="#f0f0f0"
      />
      <path
        id="a17e3a22-ba16-4351-b475-1e8c7f62d0cf-2795"
        data-name="Path 7"
        d="M829.75633,578.49163,725.04409,562.76742a5.2235,5.2235,0,0,1-1.77668-.61426,5.64264,5.64264,0,0,1-1.447-1.16631,5.41392,5.41392,0,0,1-.97425-1.54731,4.6853,4.6853,0,0,1-.3569-1.772,3.93913,3.93913,0,0,1,.3569-1.66705,3.60543,3.60543,0,0,1,.97425-1.2635,3.79765,3.79765,0,0,1,1.447-.73945,4.432,4.432,0,0,1,1.77668-.0933l104.71145,15.02209a6.46073,6.46073,0,0,1,2.07448.67413,6.61392,6.61392,0,0,1,1.69893,1.27128,5.897,5.897,0,0,1,1.14765,1.69115,4.7725,4.7725,0,0,1,.42143,1.92986,3.96,3.96,0,0,1-.42143,1.80623,3.88779,3.88779,0,0,1-1.14765,1.35447,4.51669,4.51669,0,0,1-1.69893.77288,5.56955,5.56955,0,0,1-2.07448.06454Z"
        transform="translate(-299.00754 -147.50507)"
        fill="#e4e4e4"
      />
      <path
        id="e3c2f847-4324-4f71-a395-b34127131530-2796"
        data-name="Path 8"
        d="M811.76712,559.62692l-70.8892-9.83278a5.34474,5.34474,0,0,1-1.81944-.60415,5.686,5.686,0,0,1-1.482-1.16631,5.39748,5.39748,0,0,1-.99759-1.55508,4.66515,4.66515,0,0,1-.36622-1.7899,3.96545,3.96545,0,0,1,.36544-1.69038,3.70577,3.70577,0,0,1,.99759-1.28838,3.95135,3.95135,0,0,1,1.482-.76277,4.59911,4.59911,0,0,1,1.82022-.112l70.8892,9.35693a6.17314,6.17314,0,0,1,2.0216.63836,6.33561,6.33561,0,0,1,1.65616,1.234,5.7226,5.7226,0,0,1,1.11889,1.65461,4.72427,4.72427,0,0,1,.41054,1.89953,3.98578,3.98578,0,0,1-.41054,1.78835,3.90089,3.90089,0,0,1-1.11889,1.35214,4.44519,4.44519,0,0,1-1.65616.78532,5.372,5.372,0,0,1-2.0216.091Z"
        transform="translate(-299.00754 -147.50507)"
        fill="#e4e4e4"
      />
      <path
        id="e0ac5af8-db2c-46e2-8c29-d7faefa5f799-2797"
        data-name="Path 9"
        d="M589.93312,516.17483,312.71639,480.68242a4.53866,4.53866,0,0,1-2.07992-.86,6.84813,6.84813,0,0,1-1.69193-1.8249,9.47649,9.47649,0,0,1-1.13754-2.52779,10.77562,10.77562,0,0,1-.41754-2.97175V297.4292a10.57459,10.57459,0,0,1,.41754-2.95466,8.99687,8.99687,0,0,1,1.13754-2.4718,6.39618,6.39618,0,0,1,1.69115-1.74869,4.33632,4.33632,0,0,1,2.07993-.76432l277.2175-22.698a8.15486,8.15486,0,0,1,6.47769,2.3715,8.99926,8.99926,0,0,1,1.97807,2.99275,10.08617,10.08617,0,0,1,.7301,3.80374V507.4275a9.83164,9.83164,0,0,1-.7301,3.76951,8.44794,8.44794,0,0,1-1.97807,2.90178,7.75778,7.75778,0,0,1-2.92046,1.74947A8.08021,8.08021,0,0,1,589.93312,516.17483Z"
        transform="translate(-299.00754 -147.50507)"
        fill="#fff"
      />
      <path
        id="ef100bb2-3038-4c55-8635-fb645de4c704-2798"
        data-name="Path 10"
        d="M480.32159,387.08047a18.362,18.362,0,0,0-2.50213-2.65764,16.40722,16.40722,0,0,0-2.845-1.98895,14.811,14.811,0,0,0-3.09305-1.27439,13.77657,13.77657,0,0,0-3.247-.51551,13.43689,13.43689,0,0,0-3.23924.27292,13.83042,13.83042,0,0,0-3.07207,1.03024,14.88362,14.88362,0,0,0-2.81858,1.73936,16.4839,16.4839,0,0,0-2.47958,2.40183l-74.59185,89.14274a8.469,8.469,0,0,1-3.21747,2.42749,7.23111,7.23111,0,0,1-3.63345.51006,8.4105,8.4105,0,0,1-3.60856-1.33737,11.59236,11.59236,0,0,1-3.15526-3.11716l-9.881-14.20489a15.452,15.452,0,0,0-4.28891-4.24693,10.83749,10.83749,0,0,0-4.83009-1.72848,9.33052,9.33052,0,0,0-4.78343.80864,11.33429,11.33429,0,0,0-4.16529,3.3652l-17.87645,22.6661L572.891,511.924v-4.432Z"
        transform="translate(-299.00754 -147.50507)"
        fill={secondaryColor}
      />
      <path
        id="b26a6d5f-b740-4ee8-acda-543250f0498e-2799"
        data-name="Path 11"
        d="M589.93232,519.52136l-277.21593-36.27a6.09516,6.09516,0,0,1-2.78515-1.16009,9.19454,9.19454,0,0,1-2.26264-2.45,12.71075,12.71075,0,0,1-1.51932-3.38463,14.44449,14.44449,0,0,1-.55827-3.974V297.56138a14.17051,14.17051,0,0,1,.55827-3.94835,12.06865,12.06865,0,0,1,1.51932-3.31466,8.58773,8.58773,0,0,1,2.26186-2.34584,5.82071,5.82071,0,0,1,2.78516-1.0318L589.93154,263.439a10.93146,10.93146,0,0,1,8.69915,3.163,12.05788,12.05788,0,0,1,2.66306,4.01523,13.51845,13.51845,0,0,1,.98282,5.11V507.80538a13.1767,13.1767,0,0,1-.98282,5.06491,11.31772,11.31772,0,0,1-2.66306,3.89237,10.397,10.397,0,0,1-3.92658,2.33884,10.838,10.838,0,0,1-4.77255.42064ZM312.71639,292.05872a2.85658,2.85658,0,0,0-1.37313.50073,4.21293,4.21293,0,0,0-1.11811,1.15232,5.93119,5.93119,0,0,0-.75266,1.63283,6.97379,6.97379,0,0,0-.2768,1.95085V472.70873a7.1099,7.1099,0,0,0,.2768,1.96328,6.24688,6.24688,0,0,0,.75266,1.66939,4.50959,4.50959,0,0,0,1.11966,1.20907,2.99034,2.99034,0,0,0,1.37314.56372l277.21594,34.7071a5.32846,5.32846,0,0,0,2.3443-.22,5.11918,5.11918,0,0,0,1.92131-1.157,5.575,5.575,0,0,0,1.3-1.91274,6.487,6.487,0,0,0,.479-2.48114V276.19144a6.65505,6.65505,0,0,0-.479-2.50291,5.93809,5.93809,0,0,0-1.3-1.97262,5.37987,5.37987,0,0,0-4.26559-1.57374Z"
        transform="translate(-299.00754 -147.50507)"
        fill="#cbcbcb"
      />
      <polygon points="52.59 554.197 60.925 554.196 64.89 522.047 52.589 522.048 52.59 554.197" fill="#ffb6b6" />
      <path
        d="M349.47175,698.98053l16.41452-.00067h.00066a10.46118,10.46118,0,0,1,10.46062,10.46045v.33993l-26.8753.001Z"
        transform="translate(-299.00754 -147.50507)"
        fill="#2f2e41"
      />
      <polygon
        points="129.414 565.197 137.749 565.196 141.714 533.047 129.413 533.048 129.414 565.197"
        fill="#ffb6b6"
      />
      <path
        d="M426.2957,709.98053l16.41452-.00067h.00066a10.46118,10.46118,0,0,1,10.46062,10.46045v.33993l-26.87531.001Z"
        transform="translate(-299.00754 -147.50507)"
        fill="#2f2e41"
      />
      <polygon points="119.351 306.919 124.79 341.592 73.12 342.272 79.919 308.959 119.351 306.919" fill="#ffb6b6" />
      <path
        d="M422.29753,480.63108,438.754,524.44975l2.03957,181.59166H426.85644l-33.313-145.89912L362.9498,691.2326H349.35264l9.518-148.76661s-2.80677-32.067,11.55759-50.98935l3.4964-10.1014Z"
        transform="translate(-299.00754 -147.50507)"
        fill="#2f2e41"
      />
      <path
        d="M351.70887,514.79a10.19953,10.19953,0,0,0,6.53494-14.20908L380.22468,428.918,364.0569,425.713l-20.10366,70.24468A10.25483,10.25483,0,0,0,351.70887,514.79Z"
        transform="translate(-299.00754 -147.50507)"
        fill="#ffb6b6"
      />
      <path
        d="M380.6991,473.57388a4.63163,4.63163,0,0,1-.89845-.08867h0a4.64616,4.64616,0,0,1-3.72172-4.20937c-.51109-6.99757-3.04347-19.178-7.52683-36.20372a28.65392,28.65392,0,0,1,45.24584-29.92092,28.28378,28.28378,0,0,1,10.91261,19.34225c2.22823,19.62278-1.9726,39.32442-3.882,46.88414a4.65629,4.65629,0,0,1-4.41359,3.50768l-35.61687.68752C380.765,473.57351,380.73217,473.57388,380.6991,473.57388Z"
        transform="translate(-299.00754 -147.50507)"
        fill="#3f3d56"
      />
      <path
        d="M480.20608,410.62511a9.99489,9.99489,0,0,0-.52566,1.48331l-44.58224,15.14941-8.6146-7.52574-13.05693,11.51649,13.95571,14.80492a7.59552,7.59552,0,0,0,8.98216,1.55418l47.70575-24.368a9.96639,9.96639,0,1,0-3.86419-12.61458Z"
        transform="translate(-299.00754 -147.50507)"
        fill="#ffb6b6"
      />
      <path
        d="M433.544,428.08585l-16.25214,14.195a4.27253,4.27253,0,0,1-6.40544-.909l-11.62473-18.10191a11.86557,11.86557,0,0,1,17.91905-15.5586l16.32413,13.905a4.27252,4.27252,0,0,1,.03913,6.4695Z"
        transform="translate(-299.00754 -147.50507)"
        fill="#3f3d56"
      />
      <path
        d="M377.65687,447.26663l-20.08419-7.89024a4.27251,4.27251,0,0,1-2.1426-6.10452l10.71576-18.65439a11.86557,11.86557,0,0,1,22.06218,8.742l-4.82636,20.89338a4.27253,4.27253,0,0,1-5.72479,3.01376Z"
        transform="translate(-299.00754 -147.50507)"
        fill="#3f3d56"
      />
      <circle cx="93.53733" cy="223.41815" r="21.84663" fill="#ffb6b6" />
      <path
        d="M421.7922,363.55877H387.10234V348.43755c7.614-3.02492,15.06476-5.59744,19.56864,0a15.1213,15.1213,0,0,1,15.12122,15.1212Z"
        transform="translate(-299.00754 -147.50507)"
        fill="#2f2e41"
      />
      <circle cx="86.0948" cy="221.05369" r="25" fill="#2f2e41" />
      <circle cx="68.0948" cy="202.05369" r="16" fill="#2f2e41" />
    </svg>
  );
}
