import { decodeValue } from '../../../utils';

export function TestsPassed() {
  const queryParams = new URLSearchParams(window.location.search);
  const secondary = decodeValue(queryParams.get('secondaryColor'));
  const secondaryColor = secondary ? `${secondary}` : '#606B85';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      width="350"
      height="250"
      viewBox="0 0 837.86522 658.41774"
    >
      <path
        d="M434.682,374.66791c-4.48375-5.80964-10.82036-11.18521-11.22166-18.35426-.36785-6.57533,4.535-13.10364,2.46735-19.39427-1.59-4.83678-6.8351-7.75525-11.9605-9.30074a46.48536,46.48536,0,0,0-29.873,1.15007l.51886-.3659c-5.83446-2.07832-12.6544.06721-17.24475,4.04111-4.59087,3.97384-7.31895,9.48206-9.43568,15.0019-2.11725,5.51985-3.75745,11.24158-6.69871,16.41649-2.9418,5.17492-7.4454,9.88107-13.38751,11.66953,1.98924,3.968,8.20776,5.32925,11.85495,2.595a14.99561,14.99561,0,0,0,2.49452-2.57884q3.64849-4.43668,7.29752-8.87338c2.50288,4.45149-1.23628,10.59542,1.84032,14.71654,1.58637,2.12522,4.48375,2.93232,7.17473,3.51121a129.78134,129.78134,0,0,0,63.4915-2.355c1.659-.48889,3.48939-1.16216,4.17755-2.66375C436.97225,378.15012,435.86085,376.19563,434.682,374.66791Z"
        transform="translate(-181.06739 -120.79113)"
        fill="#2f2e41"
      />
      <path
        d="M494.31719,397.34035a11.2646,11.2646,0,0,0-.25608,1.75514l-45.96973,26.51976-11.1736-6.43244-11.9117,15.59412,18.674,13.3097a8.561,8.561,0,0,0,10.27124-.25024L501.354,410.44007a11.23318,11.23318,0,1,0-7.03684-13.09972Z"
        transform="translate(-181.06739 -120.79113)"
        fill="#ffb7b7"
      />
      <path
        d="M446.55456,426.87131l-14.8573,19.25575a4.81559,4.81559,0,0,1-7.281.3988l-16.81947-17.4657a13.37376,13.37376,0,0,1,16.40139-21.12864l21.09528,11.79573a4.81558,4.81558,0,0,1,1.46113,7.14406Z"
        transform="translate(-181.06739 -120.79113)"
        fill={secondaryColor}
      />
      <polygon points="245.04 641.589 258.159 641.588 264.4 590.984 245.037 590.985 245.04 641.589" fill="#ffb7b7" />
      <path
        d="M422.76046,758.09672l25.83708-.001h.00105A16.46631,16.46631,0,0,1,465.064,774.56082v.53506l-42.30276.00157Z"
        transform="translate(-181.06739 -120.79113)"
        fill="#2f2e41"
      />
      <polygon points="155.149 641.589 168.269 641.588 174.51 590.984 155.147 590.985 155.149 641.589" fill="#ffb7b7" />
      <path
        d="M332.87016,758.09672l25.83709-.001h.001a16.46631,16.46631,0,0,1,16.46542,16.46515v.53506l-42.30276.00157Z"
        transform="translate(-181.06739 -120.79113)"
        fill="#2f2e41"
      />
      <circle
        cx="395.69003"
        cy="365.04012"
        r="22.67358"
        transform="translate(-191.4143 597.74424) rotate(-83.73558)"
        fill="#ffb7b7"
      />
      <path
        d="M408.95668,399.03041l13.35756,6.61734s14.92447,25.93654,6.36349,50.54935c-4.15646,11.94983,7.5481,50.04217,7.5481,50.04217s18.13484,172.54332,18.19209,225.79585c-22.52982,3.464-43.93228,7.74446-45.0024-2.95677S396.6313,591.84907,396.6313,591.84907,380.52221,732.28872,367.68074,734.429s-38.52441-1.07012-38.52441-1.07012,20.38958-205.71712,34.30117-218.55859l2.14024-10.70123-.05724-96.05743,16.02786-7.64887Z"
        transform="translate(-181.06739 -120.79113)"
        fill="#2f2e41"
      />
      <path
        d="M361.17262,544.85631a11.496,11.496,0,0,0,3.52191-17.27232L380.2292,424.03692l-20.29571-.97433L347.82384,526.18108a11.55829,11.55829,0,0,0,13.34878,18.67523Z"
        transform="translate(-181.06739 -120.79113)"
        fill="#ffb7b7"
      />
      <path
        d="M379.78818,449.98193l-24.27292-1.53244a4.81556,4.81556,0,0,1-4.40623-5.81012l5.05764-23.71425a13.37376,13.37376,0,0,1,26.68923,1.76352l2.03424,24.08344a4.81559,4.81559,0,0,1-5.102,5.20985Z"
        transform="translate(-181.06739 -120.79113)"
        fill={secondaryColor}
      />
      <path
        d="M419.45369,350.30133c-.88358-4.008-3.75326-7.32874-7.05925-9.76075a28.91116,28.91116,0,0,0-36.95894,2.24749,18.88671,18.88671,0,0,0-5.708,9.14869,11.22147,11.22147,0,0,0,2.39158,10.21763l.453.28373c-.41071-1.74215.94262-3.483,2.5593-4.25156a20.45,20.45,0,0,1,5.22051-1.17051,19.19262,19.19262,0,0,0,13.0092-9.17991,21.67461,21.67461,0,0,0,12.42136,12.9448c2.99666,1.17189,11.22741,2.78334,14.08037,1.29553C423.50219,360.17874,420.33728,354.30939,419.45369,350.30133Z"
        transform="translate(-181.06739 -120.79113)"
        fill="#2f2e41"
      />
      <path
        id="a7292837-b693-4341-9d08-f79d69c00516-2800"
        data-name="Path 438"
        d="M252.62151,731.121a24.21457,24.21457,0,0,0,23.38268-4.11877c8.18977-6.87441,10.758-18.196,12.84671-28.68191l6.17973-31.01657-12.9377,8.90837c-9.30465,6.40641-18.81827,13.01866-25.26011,22.29785s-9.25223,21.94707-4.07792,31.988"
        transform="translate(-181.06739 -120.79113)"
        fill="#e6e6e6"
      />
      <path
        id="bbe87a9d-22ff-4c97-8b47-e161a95b9ece-2801"
        data-name="Path 439"
        d="M254.61922,770.83157c-1.62839-11.86368-3.30382-23.88078-2.15885-35.87167,1.01467-10.64932,4.26374-21.0488,10.87832-29.57938a49.20624,49.20624,0,0,1,12.62465-11.44039c1.26216-.79647,2.4241,1.20354,1.16733,1.997a46.77947,46.77947,0,0,0-18.50445,22.32562c-4.02858,10.24607-4.67545,21.41582-3.98154,32.3003.41944,6.58218,1.31074,13.1212,2.20588,19.65251a1.19817,1.19817,0,0,1-.808,1.42251,1.16349,1.16349,0,0,1-1.42254-.808Z"
        transform="translate(-181.06739 -120.79113)"
        fill="#f2f2f2"
      />
      <path
        id="ed3d176e-02dd-4f6a-96fc-dfdec80464da-2802"
        data-name="Path 442"
        d="M266.34024,751.79512a17.82514,17.82514,0,0,0,15.53142,8.01862c7.8644-.37318,14.41806-5.85973,20.31713-11.07027l17.452-15.4088-11.54987-.5528c-8.3062-.39784-16.82672-.771-24.73814,1.79338s-15.20757,8.72638-16.654,16.9154"
        transform="translate(-181.06739 -120.79113)"
        fill="#e6e6e6"
      />
      <path
        id="ae799342-61c9-47cf-bc03-dd36b89c58fd-2803"
        data-name="Path 443"
        d="M250.01623,777.65126c7.83972-13.87142,16.93235-29.28794,33.18081-34.21552a37.02581,37.02581,0,0,1,13.95544-1.441c1.4819.128,1.1118,2.41174-.367,2.28453a34.39833,34.39833,0,0,0-22.27164,5.89215c-6.27994,4.27454-11.16975,10.21756-15.30782,16.51907-2.5351,3.86051-4.80576,7.88445-7.07641,11.903C251.40406,779.878,249.28223,778.95043,250.01623,777.65126Z"
        transform="translate(-181.06739 -120.79113)"
        fill="#f2f2f2"
      />
      <path
        d="M779.77819,521.20381c-14.71126-19.68714-41.63634-24.43848-55.016-14.4405s-16.45311,37.16578-1.74185,56.85293c13.61709,18.22289,38.28787,23.19272,51.87646,16.36551a1.70649,1.70649,0,0,0-.008.64695l.76611,3.78748a1.72814,1.72814,0,0,0,2.75776,1.0192l6.48745-5.06824a1.7282,1.7282,0,0,0-.3215-2.92254l-3.48972-1.65969a1.71065,1.71065,0,0,0-.384-.12059C791.17906,564.591,793.42489,539.46631,779.77819,521.20381Z"
        transform="translate(-181.06739 -120.79113)"
        fill="#ccc"
      />
      <path
        d="M747.5991,513.77089a28.43178,28.43178,0,0,1,23.81222,31.08457c-.15814,1.65975,2.43513,1.6489,2.59221,0a31.0881,31.0881,0,0,0-25.71532-33.58418C746.6565,510.99391,745.95531,513.49146,747.5991,513.77089Z"
        transform="translate(-181.06739 -120.79113)"
        fill="#fff"
      />
      <path
        d="M803.75721,180.89617c12.86165-20.94237,7.33248-47.71853-6.90025-56.45949s-40.61475-1.56289-53.4764,19.37948c-11.905,19.38475-7.41153,44.14672,3.95179,54.25293a1.70639,1.70639,0,0,0-.60414.23154l-3.23673,2.11082a1.72815,1.72815,0,0,0,.07142,2.9392l7.106,4.15683a1.7282,1.7282,0,0,0,2.59716-1.37818l.25343-3.856a1.71054,1.71054,0,0,0-.02976-.40138C767.64859,207.51564,791.82629,200.32307,803.75721,180.89617Z"
        transform="translate(-181.06739 -120.79113)"
        fill="#ccc"
      />
      <path
        d="M798.77948,148.24706a28.43177,28.43177,0,0,1-20.09183,33.60938c-1.6008.46606-.63292,2.872.95742,2.40893a31.08812,31.08812,0,0,0,21.71181-36.30114C801.012,146.34545,798.432,146.61629,798.77948,148.24706Z"
        transform="translate(-181.06739 -120.79113)"
        fill="#fff"
      />
      <path
        d="M575.82414,280.2624c-1.07548-24.553-20.65376-43.63767-37.34031-42.90675S503.96269,258.81,505.03817,283.363c.99549,22.72682,18.58763,40.723,33.66183,42.7303a1.70628,1.70628,0,0,0-.37079.53021l-1.499,3.56158a1.72815,1.72815,0,0,0,1.70547,2.39487l8.215-.53663a1.7282,1.7282,0,0,0,1.37957-2.59642l-1.94985-3.33629a1.711,1.711,0,0,0-.24947-.31583C560.82141,322.53942,576.8218,303.03861,575.82414,280.2624Z"
        transform="translate(-181.06739 -120.79113)"
        fill={secondaryColor}
      />
      <path
        d="M553.41313,256.0036a28.43178,28.43178,0,0,1,2.18095,39.09624c-1.06507,1.28273,1.08433,2.73368,2.14242,1.45932a31.0881,31.0881,0,0,0-2.34664-42.2335C554.19743,253.17783,552.21188,254.84727,553.41313,256.0036Z"
        transform="translate(-181.06739 -120.79113)"
        fill="#fff"
      />
      <path
        d="M652.33451,443.14446a40.78437,40.78437,0,0,1-31.9-18.89576c-.96575-1.54387-2.22647-3.24048-1.55793-5.14081a3.37989,3.37989,0,0,1,3.50209-2.339c1.97113.23991,3.24314,2.16819,3.486,4.01584.27545,2.09589-1.20316,3.74123-3.02866,4.52-4.461,1.90287-8.96171-1.03789-12.59546-3.38487-8.27977-5.34782-17.38675-10.18417-27.41958-10.79362-9.28262-.56383-19.23682,3.07583-24.4,11.12566-.90331,1.40833,1.33949,2.70972,2.23831,1.30834,5.12059-7.98343,15.4722-10.85073,24.47281-9.6168a47.5761,47.5761,0,0,1,15.31321,5.199c2.4739,1.28388,4.87325,2.70708,7.2241,4.20318a46.09864,46.09864,0,0,0,7.08548,4.06183c4.1316,1.69718,9.601,1.5646,12.487-2.34292,2.69239-3.64546.61163-9.71453-3.89154-10.7394a6.08493,6.08493,0,0,0-7.128,7.239c.44931,2.14673,2.0658,4.24753,3.31706,6.0022a43.37946,43.37946,0,0,0,32.79526,18.17034c1.66744.08248,1.66309-2.50994,0-2.59222Z"
        transform="translate(-181.06739 -120.79113)"
        fill="#ccc"
      />
      <path
        d="M901.28453,264.54682c7.097-3.30155,15.81045-4.38389,23.03182-1.316s12.14453,10.93153,9.80947,17.89083c-1.05868,3.15524-3.46989,6.25835-2.5305,9.445.72306,2.45284,3.24546,4.07226,5.72259,5.22372s5.18694,2.11951,6.97841,4.06155,2.1651,5.28532-.07285,6.79138c-.73732.49619-1.65886.7315-2.37376,1.25437a3.772,3.772,0,0,0-1.16432,4.2222,8.89321,8.89321,0,0,0,2.85084,3.75065c2.54053,2.19094,5.89807,4.6985,5.10926,7.80873a5.47836,5.47836,0,0,1-3.697,3.45789,18.36751,18.36751,0,0,1-5.42687.71626L864.5554,330.215a28.4224,28.4224,0,0,1-7.40166-.41344,8.7618,8.7618,0,0,1-5.81294-3.905c-1.4356-2.65728-.49311-5.93057,1.2798-8.41186s4.282-4.43863,6.35524-6.71709,3.76948-5.12271,3.404-8.06748c-.29255-2.35732-1.84718-4.3947-2.9632-6.5366s-1.76428-4.81847-.31887-6.78893c2.03679-2.77665,6.92687-2.52551,9.24285-5.11284,1.74777-1.95255,1.41026-4.76345,1.584-7.28134.418-6.05656,4.61118-11.77645,10.58027-14.43257a20.83057,20.83057,0,0,1,18.95323,1.2908Z"
        transform="translate(-181.06739 -120.79113)"
        fill="#2f2e41"
      />
      <polygon points="811.513 443.477 811.513 455.737 764.225 461.57 764.226 443.475 811.513 443.477" fill="#9e616a" />
      <path
        d="M1004.46466,561.14195l-.00147,39.53076h-.5A15.3873,15.3873,0,0,1,988.577,585.28624v-.001l.001-24.144Z"
        transform="translate(-181.06739 -120.79113)"
        fill="#2f2e41"
      />
      <polygon
        points="753.056 464.332 750.046 476.216 702.773 470.262 707.216 452.722 753.056 464.332"
        fill="#9e616a"
      />
      <path
        d="M946.41118,585.00928l-9.70511,38.32091-.4847-.12274a15.38729,15.38729,0,0,1-11.13852-18.69259l.00024-.00094,5.92763-23.40509Z"
        transform="translate(-181.06739 -120.79113)"
        fill="#2f2e41"
      />
      <path
        d="M922.05028,447.63717l2.553,2.66s16.66164,13.06488,4.33455,47.48l-20.16457,58.09,67.58839,2.63549-1.44837,26.54451s-98.0174,5.45217-97.16-10.70137,4.47388-75.96494,4.47388-75.96494l-20.364-16.35371,4.91-27.66Z"
        transform="translate(-181.06739 -120.79113)"
        fill="#2f2e41"
      />
      <path
        d="M869.69322,473.99938l-7.83,8.02781-26.2525,72.1651s-4,18,5,22,66.9096,26.37548,66.9096,26.37548l5.91323-21.28278-44.62032-26.78167,20.79749-27.311,3.12992-43.04511Z"
        transform="translate(-181.06739 -120.79113)"
        fill="#2f2e41"
      />
      <path
        d="M870.1107,346.69229l66.63531-13.324,1.44808,36.55891-17.26082,36.79,3.67,43.58L864.883,460.73111l1.87031-57.3039s-16.03772-22.62506-6.59015-45.43Z"
        transform="translate(-181.06739 -120.79113)"
        fill="#e4e4e4"
      />
      <path
        d="M1018.69688,259.96661a10.74263,10.74263,0,0,1-10.09187,13.01927l-63.89979,74.09734-14.85634-18.02989,67.48291-67.16584a10.80091,10.80091,0,0,1,21.36509-1.92088Z"
        transform="translate(-181.06739 -120.79113)"
        fill="#9e616a"
      />
      <path
        d="M958.618,338.998a4.81171,4.81171,0,0,1-1.59192,3.68145l-17.96772,16.17487a13.37737,13.37737,0,0,1-20.675-16.981l12.542-20.75959a4.81686,4.81686,0,0,1,7.19077-1.22225l18.7543,15.49575A4.81147,4.81147,0,0,1,958.618,338.998Z"
        transform="translate(-181.06739 -120.79113)"
        fill="#e4e4e4"
      />
      <path
        d="M765.62579,434.10027a10.74268,10.74268,0,0,1,11.93116-11.3576l74.29461-63.6703,11.98277,20.05494L787.03723,435.407a10.80091,10.80091,0,0,1-21.41144-1.30669Z"
        transform="translate(-181.06739 -120.79113)"
        fill="#9e616a"
      />
      <path
        d="M836.8831,364.97845a4.81166,4.81166,0,0,1,2.1263-3.40091l20.19132-13.2957a13.37737,13.37737,0,0,1,17.89292,19.89105L861.5787,386.81554a4.81689,4.81689,0,0,1-7.29277.12944L838.0691,368.81059A4.81141,4.81141,0,0,1,836.8831,364.97845Z"
        transform="translate(-181.06739 -120.79113)"
        fill="#e4e4e4"
      />
      <circle cx="717.44336" cy="184.2819" r="24.56103" fill="#9e616a" />
      <path
        d="M923.63744,301.58116c-7.75643-.62285-14.19624-8.37141-13.38973-16.11089A13.00908,13.00908,0,0,1,899.1065,298.716c-3.55788.392-7.4584-.68443-10.55525,1.11048-3.43,1.988-4.52757,6.81578-8.10091,8.53283-3.45254,1.659-7.8377-.60362-9.54345-4.03331s-1.28713-7.5502-.15669-11.21005a31.65248,31.65248,0,0,1,52.68951-12.97513c3.26142,3.28048,5.851,7.46146,6.271,12.06821s-1.717,9.60534-5.85415,11.67485Z"
        transform="translate(-181.06739 -120.79113)"
        fill="#2f2e41"
      />
      <path
        d="M563.06739,776.69229h-381a1,1,0,1,1,0-2h381a1,1,0,1,1,0,2Z"
        transform="translate(-181.06739 -120.79113)"
        fill="#cacaca"
      />
    </svg>
  );
}
